import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AccessRequest } from '../_models/access-request';
import { ErrorMessages } from 'src/app/_helpers/app.errorMessages';
import { GroupPolicies, Groups, Policy } from '../_models/constants/setting';
import { User } from '../_models/user';
import { CommonService } from './common/common.service';
import af from 'date-fns/esm/locale/af/index.js';
import firebase from 'firebase/compat/app';



@Injectable()
export class AuthService {
  enquiriesRef = this.firestore.collection('enquiries');
  usersRef = this.firestore.collection('users');

  constructor(
   public afAuth: AngularFireAuth,
   private firestore: AngularFirestore,
   private commonService: CommonService,
 ) { 
    this.afAuth.onAuthStateChanged(user=>{
      if (!user) this.doLogout();
    })
 }



  public getLoggedInUser(): User {
      return <User>JSON.parse(localStorage.getItem('currentUser'));
  }


  public getLocalSiteSettings() {
    return JSON.parse(localStorage.getItem('siteSettings'));
  }

  public getSiteSettings() {
    return this.firestore.collection('siteConfig').doc('settings').valueChanges();
  }

  public isSuper(): Boolean {
    let user = <User>JSON.parse(localStorage.getItem('currentUser'));
    if (user.policy.groupPolicy == GroupPolicies.InternalSuper && user.policy.score >= 30) 
      return true;
    else
      return false;
  }

  public isAdmin(): Boolean {
    let user = <User>JSON.parse(localStorage.getItem('currentUser'));
    if (user.policy.group == Groups.InternalUser && user.policy.score >= 10) 
      return true;
    else
      return false;
  }

  public getUID(): string{
    return (<User>JSON.parse(localStorage.getItem('currentUser'))).uid;
  }
  
  getLoggendInUserGroupPolicy() : Promise<Policy>{
    let policy: Policy;

    return new Promise<Policy>(async (resolve, reject) => {
      if (this.afAuth.currentUser){
        let idTokenResult = await (await this.afAuth.currentUser).getIdTokenResult();
        if (idTokenResult.claims) {
          policy = {
            group: idTokenResult.claims.group,
            role: idTokenResult.claims.role,
            groupPolicy: idTokenResult.claims.groupPolicy,
            score: idTokenResult.claims.score,
            companyId: idTokenResult.claims.companyId
          }
        } else {
          policy = null;
        }
        resolve(policy);
      } else {
        reject(null);
      }    
    })
  }

  doRequestAccess(value: AccessRequest) {
    return new Promise<any>((resolve, reject) => {
      value = { ...value, requestDateTime: new Date()}
      this.enquiriesRef.add(value).then(res=>{
        resolve(res)
      }, err=> reject(err));
    })

  }

  doSendPasswordResetEmail(value) {
    return new Promise<any>((resolve, reject)=>{
      this.afAuth.sendPasswordResetEmail(value.email).then(res=>{
        resolve(res)
      }, err=>{
        reject(err);
      })
    })
  }

  doVerifyPasswordResetCode(value) {
    return new Promise<any>((resolve, reject)=>{
      this.afAuth.verifyPasswordResetCode(value).then(res=>{
        resolve(res)
      }, err=>{
        reject(err);
      })
    })
  }

  doResetPassword(value) {
    return new Promise<any>((resolve, reject)=>{
      this.afAuth.confirmPasswordReset(value.oobCode, value.password).then(res=>{
        resolve(res)
      }, err=>{
        reject(err);
      })
    })
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {

      this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        this.afAuth.signInWithEmailAndPassword(value.email, value.password)
        .then(async res => {
          let policy = await this.getLoggendInUserGroupPolicy();
          //start listner for auth changes TODO
          this.usersRef.doc(res.user.uid).get().subscribe(resp=>{
  
            console.log('login', resp.data())
            if (resp.data() !== undefined) {
              let response = {uid: res.user.uid, policy:policy, ...(resp.data() as object)};
              this.setUserInStorage(response);
              resolve(response);
            }
            else  {
              reject ({ message: ErrorMessages.ERROR_USER_NOT_FOUND})
            }
          }, err => reject (err));
        }, err => reject(err));
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });
    
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (this.afAuth.currentUser) {
        this.afAuth.signOut().then(res=>{
          this.removeUserFromStorage();
          resolve(res);
        }, error=>{
          reject(error);
        });
      }
    });
  }

  setUserInStorage(res) {
    // this.usersService.getUserGroupRolePolicy(res.uid).subscribe(policy=>{
      // if (policy.data()) {
        // let user = { policy: policy.data(), ...res}
          localStorage.setItem('currentUser', JSON.stringify(res));
        // }

        // sessionStorage.setItem('currentUser', JSON.stringify(res));
      // }
    // })
  }

  removeUserFromStorage() {
    console.log('removing current user from storage');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('siteSettings');
  }
  
}
